<!-- 规则编辑 -->
<template>
  <el-dialog
      width="45%"
      center
      :visible="visible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'编辑规则':'新增规则'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px">


      <el-row :gutter="15">
        <el-col :span="18">
          <el-form-item label="规则名称:" prop="name">
            <el-input
              clearable
              v-model="form.name"
              placeholder="请输入规则名称"/>
          </el-form-item>
          <el-form-item label="救援类型:" prop="">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="事故救援"></el-checkbox>
              <el-checkbox label="非事故救援"></el-checkbox>
              <el-checkbox label="高速救援"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="救援项目:" prop="">
            <el-checkbox-group v-model="checkList1">
              <el-checkbox label="拖车"></el-checkbox>
              <el-checkbox label="搭电"></el-checkbox>
              <el-checkbox label="送油"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="计价类型:" prop="">
            <el-checkbox-group v-model="checkList2">
              <el-checkbox label="小型车(5吨以内)"></el-checkbox>
              <el-checkbox label="客车、货车(5吨-10吨)客车"></el-checkbox>
              <el-checkbox label="货车(10吨以上)重型货车"></el-checkbox>
              <el-checkbox label="半挂(15吨以上)两轮"></el-checkbox>
              <el-checkbox label="两轮、三轮机动车"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <div class="provinceAndCity">
            <div class="provinceAndCity_t">
              <span style="vertical-align: middle">区域：</span>
              <el-checkbox v-model="selectAll" @change="handleCheckAllChange">全选</el-checkbox>
            </div>
            <!--省市区-->
            <div class="provinceAndCity_b">
              <!--@check="treeCheck"  实现单选-->
              <el-tree
                :data="city"
                ref="tree"
                show-checkbox
                node-key="value"
                :default-checked-keys="[keys]"
                @check-change="handleCheckChange"
                :props="defaultProps">
              </el-tree>
            </div>
          </div>
        </el-col>
      </el-row>

    </el-form>

    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">提交
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
//引入的接口
import {
  taximeterget_city
} from '@/api/yunli'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        // organization_category:[
        //   { required: true, message: '请选择机构类型', trigger: 'change' },
        // ],
        name: [
          { required: true, message: '请输入规则名称', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        // coding: [
        //   { required: true, message: '请输入编码', trigger: 'blur' },
        // ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 省市区
      //城市列表
      city:[],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      //默认选中的节点
      keys:[],
      selectList:[],
      // 区域 全选按钮
      selectAll:false,

      checkList:[],
      checkList1:[],
      checkList2:[]

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },

  mounted() {
    this.getCity();
  },

  methods: {
    // 获取城市列表
    getCity(){
      taximeterget_city().then(res => {
        console.log(res);
        this.city = res.data;
      })
    },
    //城市全选
    handleCheckAllChange(val) {
      if (this.selectAll) {
        this.$refs.tree.setCheckedNodes(this.city);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    //获取选择的区域
    handleCheckChange(){
      //console.log(this.$refs.tree.getCheckedNodes());  // 这是选中节点数组
      this.selectList = this.$refs.tree.getCheckedNodes();
    },


    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){

          }else {

          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
.provinceAndCity{
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}
.provinceAndCity_b{
  margin-top: 10px;
}
</style>
