<!-- 小组编辑 -->
<template>
  <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'编辑小组':'新增小组'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px">
      <el-form-item label="小组名称:" prop="name">
        <el-input
          clearable
          v-model="form.name"
          placeholder="请输入小组名称"/>
      </el-form-item>
      <el-form-item label="权重:" prop="">
        <el-input placeholder="请输入" v-model="form.input2" oninput="value=value.replace(/^0|[^0-9]/g,'')">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <!--<el-form-item label="所属规则:" prop="">-->
      <!--  <el-select v-model="form.value" placeholder="请选择">-->
      <!--    <el-option label="规则1" value="1"></el-option>-->
      <!--  </el-select>-->
      <!--</el-form-item>-->

    </el-form>

    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">提交
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        // organization_category:[
        //   { required: true, message: '请选择机构类型', trigger: 'change' },
        // ],
        name: [
          { required: true, message: '请输入小组名称', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        // coding: [
        //   { required: true, message: '请输入编码', trigger: 'blur' },
        // ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 省市区
      //城市列表
      city:[],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      //默认选中的节点
      keys:[],
      selectList:[],
      // 区域 全选按钮
      selectAll:false,

      checkList:[],
      checkList1:[],
      checkList2:[]

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },

  mounted() {

  },

  methods: {

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){

          }else {

          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
</style>
