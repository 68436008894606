<template>
  <!--小组管理-->
  <div >
    <div>
      <div class="title displaystyle">
        <div>
          小组管理列表
        </div>
        <div class="displaystylemargin">
          <el-button type="info" @click="handleClose">返回</el-button>
        </div>
      </div>
      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" @click="openEdit(null)">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">新建小组</span>
                </el-button>
              </div>
            </div>
            <div>
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >
              <!--自定义列显示 示例-->
              <template v-slot:organization_category= "{ scope }">
                <div v-for="(item) in dict.type.organization_type">
                  <span v-if="scope.row.organization_category == parseInt(item.value)">{{item.label}}</span>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="success" @click="fenpei(scope.row)">分配人员</el-link>
                <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="openEdit(scope.row)">编辑</el-link>
                <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                  <el-link slot="reference" :underline="false" type="info">删除</el-link>
                </el-popconfirm>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--小组编辑-->
      <Edit :data="current" :dict="dict" :visible.sync="showEdit" @done="getList"/>

      <!--分配人员-->
      <el-dialog
        title="分配人员"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">

        <el-form
          :model="form"
          label-width="90px">
          <el-form-item label="选择成员:" prop="">
            <el-select v-model="form.value" filterable multiple clearable placeholder="请选择" @change="selectchengyuan">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择组长:" prop="">
            <el-select v-model="form.value1" placeholder="请选择" clearable :disabled="zuzhang">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div slot="footer">
          <el-button
            @click="dialogVisible = false">取消
          </el-button>
          <el-button
            style="margin-left: 20px;"
            type="primary"
            @click="dialogVisible = false">提交
          </el-button>
        </div>
      </el-dialog>


    </div>

  </div>
</template>

<script>
//规则编辑
import Edit from './components/edit.vue'
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  Delroles,
  getroles,
} from '@/api/humanResources'
import Vue from "vue";

export default {
  // 组件生效
  components: {
    Edit,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "小组名称",
          // type: "index",
          // width: "70"
          prop: "name",
          isShow: true
        },
        {
          label: "组长",
          prop: "",
          isShow: true
        },
        {
          label: "成员",
          prop: "",
          isShow: true
        },
        {
          label: "权重",
          prop: "",
          isShow: true
        },
        {
          label: "所属规则",
          prop: "",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      //编辑
      current:null,
      showEdit:false,

      //字典
      dict: {
        type:{}
      },

      //分配人员
      dialogVisible:false,

      form:{},
      // 选择人员
      options: [{
        value: '选项1',
        label: '人员'
      }, {
        value: '选项2',
        label: '人员1'
      }, {
        value: '选项3',
        label: '人员2'
      }, {
        value: '选项4',
        label: '人员3'
      }, {
        value: '选项5',
        label: '人员3'
      }],
      //选择组长
      zuzhang:true,
      options1: [{
        value: '选项1',
        label: '人员'
      }, {
        value: '选项2',
        label: '人员1'
      }, {
        value: '选项3',
        label: '人员2'
      }, {
        value: '选项4',
        label: '人员3'
      }, {
        value: '选项5',
        label: '人员3'
      }],

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '系统字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    this.getList();
  },

  methods:{
    /* 刷新表格 */
    reload() {
      this.getList();
    },
    /* 重置搜索 */
    reset() {
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      };
      this.reload();
    },

    // 获取列表
    getList(){
      getroles(this.where).then(async (res) => {
        this.total = res.data.count;
        // this.List = res.data.list;
        this.List = [
          {
            name:'小组1',
          }
        ]
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },

    // 删除
    del(row){
      Delroles(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    //编辑小组
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    //点击返回
    handleClose(){
      this.$emit('returnPage',false)
    },

    //点击分配
    fenpei(row){
      this.dialogVisible = true;
    },

    //选择成员
    selectchengyuan(value){
      console.log(value)
      this.zuzhang = false;
    },

  }

}
</script>

<style lang="scss" scoped>
  .displaystyle{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .displaystylemargin{
    margin-left: 20px;
  }
</style>
